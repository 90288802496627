import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/RegionPage.css';
import Navbar from '../components/Navbar';
import CookiePopup from '../components/CookiePopup';
import Footer from '../components/Footer';

const RegionPage = () => {
  const { region } = useParams();
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const formatDateToEuropean = (date) => {
    if (!date) return '';
    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const apiURL = `/api/NmXQPzbauVT7vfegA64C8n?action=get&region=${encodeURIComponent(region.toLowerCase())}`;

      setLoading(true);
      setError(null);
      try {
        const response = await fetch(apiURL);
        if (!response.ok) {
          throw new Error('Errore nel caricamento degli eventi');
        }

        const data = await response.json();
        if (data.success) {
          setEvents(data.data || []);
        } else {
          setError(data.error || 'Errore sconosciuto dal server');
        }
      } catch (error) {
        setError(error.message || 'Errore di connessione');
        console.error('Errore fetch eventi:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, [region]);

  const addToCalendar = (event) => {
    const startDateTime = new Date(`${event.date}T${event.time || "12:00"}:00`);
    const endDateTime = new Date(startDateTime.getTime() + 60 * 60 * 1000); // Aggiunge 1 ora
    const title = encodeURIComponent(event.title);
    const location = encodeURIComponent(event.location);
    const description = encodeURIComponent(event.description);
    const startISO = startDateTime.toISOString().replace(/-|:|\.\d+/g, "");
    const endISO = endDateTime.toISOString().replace(/-|:|\.\d+/g, "");
  
    const userAgent = navigator.userAgent.toLowerCase();
  
    if (/android/i.test(userAgent)) {
      // ✅ Android: Usa Google Calendar
      const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${description}&location=${location}&dates=${startISO}/${endISO}`;
      window.open(googleCalendarUrl, "_blank");
  
    } else if (/iphone|ipad|ipod/i.test(userAgent)) {
      // ✅ iOS: Genera e scarica il file .ics
      const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${description}&location=${location}&dates=${startISO}/${endISO}`;
      window.open(googleCalendarUrl, "_blank");
  
    } else {
      // ✅ Desktop: Usa Google Calendar o Outlook
      const outlookUrl = `https://outlook.live.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&startdt=${event.date}T${event.time || "12:00"}Z&enddt=${event.date}T${event.time || "13:00"}Z&subject=${title}&location=${location}&body=${description}`;
      const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&details=${description}&location=${location}&dates=${startISO}/${endISO}`;
  
      // Apri Google Calendar per default
      window.open(googleCalendarUrl, "_blank");
    }
  };
  
  


const openMaps = (location) => {
  const encodedLocation = encodeURIComponent(location);

  if (/android/i.test(navigator.userAgent)) {
      // ANDROID - Usa "geo:" per aprire l'app di mappe predefinita (Google Maps, Waze, ecc.)
      window.location.href = `geo:0,0?q=${encodedLocation}`;
  } else if (/iPad|iPhone|Macintosh/i.test(navigator.userAgent)) {
      // iOS/macOS - Usa "maps:" per aprire Apple Maps o qualsiasi altra app predefinita
      window.location.href = `maps://?q=${encodedLocation}`;
  } else {
      // Fallback - Usa Google Maps nel browser per PC e altri dispositivi
      window.open(`https://www.google.com/maps/search/?api=1&query=${encodedLocation}`, "_blank");
  }
};

  return (
    <div className="page-container">
      <Navbar />
      <div className="region-page">
        <h1>Eventi in {region.charAt(0).toUpperCase() + region.slice(1)}</h1>
        {loading ? (
          <p>Caricamento eventi...</p>
        ) : error ? (
          <p className="error-message">Errore: {error}</p>
        ) : events.length === 0 ? (
          <p>Nessun evento in programma per questa regione.</p>
        ) : (
          <div className="event-list">
            {events.map((event) => (
              <div key={event.id} className="event-card">
                <h2>{event.title}</h2>
                <p><strong>Data:</strong> {formatDateToEuropean(event.date)}</p>
                <p><strong>Ora:</strong> {event.time || "Non specificata"}</p>
                <p><strong>Luogo:</strong> {event.location}</p>
                <p>{event.description}</p>

                <div className="event-buttons">
                <button onClick={() => addToCalendar(event)}>📅 Aggiungi al Calendario</button>
                <button onClick={() => openMaps(event.location)}>📍 Alla destinazione</button>
                </div>
                
                {event.image && (
                  <img src={event.image} alt={event.title} className="event-image" />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <CookiePopup />
      <Footer />
    </div>
  );
};

export default RegionPage;



