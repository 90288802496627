import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "../styles/Statuto.css"; 

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const StatutoViewer = ({ pdfUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1); // Imposta zoom iniziale a 1
  const [transitionClass, setTransitionClass] = useState("");

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setTransitionClass("fade-out");
    setTimeout(() => {
      setPageNumber((prev) => Math.min(Math.max(prev + offset, 1), numPages));
      setTransitionClass("fade-in");
    }, 200); // Tempo per l'effetto di transizione
  };


  return (
    <div className="pdf-container">
      <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} className="shadow-lg">
        <div className={`pdf-viewer ${transitionClass}`}>
          <Page pageNumber={pageNumber} scale={scale} renderTextLayer={false} renderAnnotationLayer={false} />
        </div>
      </Document>

      {/* Controlli di navigazione */}
      <div className="pdf-controls">
        <button onClick={() => changePage(-1)} disabled={pageNumber <= 1}>
          ◀ Indietro
        </button>
        <p className="pdf-page">
          Pagina {pageNumber} di {numPages}
        </p>
        <button onClick={() => changePage(1)} disabled={pageNumber >= numPages}>
          Avanti ▶
        </button>
      </div>

      {/* Pulsante per scaricare il PDF */}
      <a href={pdfUrl} download="statuto.pdf" className="download-button">
        📥 Scarica Statuto
      </a>
    </div>
  );
};

export default StatutoViewer;

