import React, { useState } from 'react';
import DOMPurify from 'dompurify';
import axios from 'axios';
import '../styles/DonationFormStep.css';

const DonationForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    comuneResidenza: '',
    provinciaResidenza: '',
    cap: '',
    indirizzo: '',
    nazione: '',
    email: '',
    cellulare: '',
    donation: 0,
    customDonation: '',
    privacy: false,
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const totalDonation = formData.customDonation
    ? parseFloat(formData.customDonation)
    : formData.donation;

  const handleChange = (field, value) => {
    const sanitizedValue = typeof value === 'string' ? DOMPurify.sanitize(value) : value;
    setFormData({ ...formData, [field]: sanitizedValue });
    setErrorMessage('');
  };

  const validateStep = () => {
    const required = {
      1: [formData.nome.trim(), formData.cognome.trim()],
      2: [formData.comuneResidenza.trim(), formData.provinciaResidenza.trim(), formData.cap.trim(), formData.indirizzo.trim(), formData.nazione.trim()],
      3: [formData.email.trim(), formData.cellulare.trim()],
      4: [totalDonation > 0],
      5: [formData.privacy],
    };

    if (!required[step].every(Boolean)) {
      setErrorMessage('Compila tutti i campi obbligatori!');
      return false;
    }
    return true;
  };

  const nextStep = () => validateStep() && setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateStep()) return;

    const sanitizedData = Object.keys(formData).reduce((acc, key) => ({
      ...acc,
      [key]: typeof formData[key] === 'string'
        ? DOMPurify.sanitize(formData[key].trim())
        : formData[key],
    }), { importo: totalDonation });

    try {
      const response = await axios.post('/api/wA5pd6q3tgNP27K8kmLVQn', sanitizedData);
      if (response.data.success) {
        setSuccessMessage('Grazie per la tua donazione!');
        setTimeout(() => setSuccessMessage(''), 5000);
        setStep(1);
        setFormData({
          nome: '', cognome: '', comuneResidenza: '', provinciaResidenza: '', cap: '',
          indirizzo: '', nazione: '', email: '', cellulare: '', donation: 0, customDonation: '', privacy: false,
        });
      }
    } catch (error) {
      setErrorMessage('Errore durante l\'invio. Riprova più tardi.');
    }
  };

  return (
    <div className="donation-form-container" style={{ marginTop: "20px" }}>
      <h2>Fai una Donazione</h2>
      {successMessage && <p className="donation-success-message">{successMessage}</p>}
      {errorMessage && <p className="donation-error-message">{errorMessage}</p>}

      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="donation-form-section">
            <h3>1. Dati personali *</h3>
            <input type="text" className="donation-input" placeholder="Nome" value={formData.nome} onChange={(e) => handleChange('nome', e.target.value)} />
            <input type="text" className="donation-input" placeholder="Cognome" value={formData.cognome} onChange={(e) => handleChange('cognome', e.target.value)} />
            <button type="button" onClick={nextStep} className="donation-button donation-button-next">Avanti</button>
          </div>
        )}

        {step === 2 && (
          <div className="donation-form-section">
            <h3>2. Indirizzo *</h3>
            <input type="text" className="donation-input" placeholder="Comune" value={formData.comuneResidenza} onChange={(e) => handleChange('comuneResidenza', e.target.value)} />
            <input type="text" className="donation-input" placeholder="Provincia" value={formData.provinciaResidenza} onChange={(e) => handleChange('provinciaResidenza', e.target.value)} />
            <input type="text" className="donation-input" placeholder="CAP" value={formData.cap} onChange={(e) => handleChange('cap', e.target.value)} />
            <input type="text" className="donation-input" placeholder="Indirizzo" value={formData.indirizzo} onChange={(e) => handleChange('indirizzo', e.target.value)} />
            <input type="text" className="donation-input" placeholder="Nazione" value={formData.nazione} onChange={(e) => handleChange('nazione', e.target.value)} />
            <div className="donation-buttons">
              <button type="button" onClick={prevStep} className="donation-button donation-button-prev">Indietro</button>
              <button type="button" onClick={nextStep} className="donation-button donation-button-next">Avanti</button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="donation-form-section">
            <h3>3. Recapiti *</h3>
            <input type="email" className="donation-input" placeholder="Email" value={formData.email} onChange={(e) => handleChange('email', e.target.value)} />
            <input type="tel" className="donation-input" placeholder="Telefono" value={formData.cellulare} onChange={(e) => handleChange('cellulare', e.target.value)} />
            <div className="donation-buttons">
              <button type="button" onClick={prevStep} className="donation-button donation-button-prev">Indietro</button>
              <button type="button" onClick={nextStep} className="donation-button donation-button-next">Avanti</button>
            </div>
          </div>
        )}

        {step === 4 && (
          <div className="donation-form-section">
            <h3>4. Importo *</h3>
            <div className="donation-options">
              {[5, 10, 20, 50].map((amount) => (
                <button
                  type="button"
                  key={amount}
                  className={formData.donation === amount ? 'selected' : ''}
                  onClick={() => handleChange('donation', amount)}
                >
                  {amount} €
                </button>
              ))}
            </div>
            <input type="number" min="1" className="donation-input" placeholder="Importo libero" value={formData.customDonation} onChange={(e) => handleChange('customDonation', e.target.value)} />
            <div className="donation-buttons">
              <button type="button" onClick={prevStep} className="donation-button donation-button-prev">Indietro</button>
              <button type="button" onClick={nextStep} className="donation-button donation-button-next">Avanti</button>
            </div>
          </div>
        )}

        {step === 5 && (
          <div className="donation-form-section">
            <h3>5. Riepilogo e Invio *</h3>
            <div className="donation-summary-card">
              <p><strong>Nome:</strong> {formData.nome}</p>
              <p><strong>Cognome:</strong> {formData.cognome}</p>
              <p><strong>Comune:</strong> {formData.comuneResidenza}</p>
              <p><strong>Provincia:</strong> {formData.provinciaResidenza}</p>
              <p><strong>CAP:</strong> {formData.cap}</p>
              <p><strong>Indirizzo:</strong> {formData.indirizzo}</p>
              <p><strong>Email:</strong> {formData.email}</p>
              <p><strong>Telefono:</strong> {formData.cellulare}</p>
              <p><strong>Importo Donazione:</strong> € {totalDonation}</p>
            </div>
            <div className="donation-bank-details">
              <h3>Dati Bancari per Bonifico</h3>
              <p><strong>IBAN:</strong> IT57 N 05034 01636 000000031728</p>
              <p><strong>Intestatario:</strong> Patto per il Nord</p>
              <p><strong>Banca:</strong> Banco BPM - Milano</p>
              <p><strong>Causale:</strong> Donazione - {formData.nome} {formData.cognome}</p>
            </div>

            <div className="donation-checkbox-row">
              <input
                type="checkbox"
                checked={formData.privacy}
                onChange={(e) => handleChange('privacy', e.target.checked)}
                required
              />
              <label>Accetto il trattamento dei dati personali (GDPR)</label>
            </div>

            <div className="donation-buttons">
              <button
                type="button"
                onClick={prevStep}
                className="donation-button donation-button-prev"
              >
                Indietro
              </button>
              <button
                type="submit"
                className="donation-button donation-button-submit"
              >
                Invia
              </button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default DonationForm;





