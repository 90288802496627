import StatutoViewer from "../components/StatutoViewer";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CookiePopup from '../components/CookiePopup';
import '../styles/Statuto.css'

const Statuto = () => {
  return (
    <>
    <Navbar />
    <div className="statutoContainer">
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6 text-center">Statuto Patto per il Nord</h1>
      <StatutoViewer pdfUrl="/assets/pdf/Statuto Patto per il Nord.pdf" />
    </div>
      <div className="postilla">
        <h1 className="text-2xl font-bold mb-5 text-center">*Si fa notare che i Padri Fondatori dell'associazioni sono molti di piu' di quelli presenti nello statuto e firmatari di quest'ultimo</h1>
        <h1 className="text-2xl font-bold mb-5 text-center">**Si invitano tutti coloro che rappresentano una lista civica, un'associzione, un comitato, etc... se interessati al progetto a contattarci al piu' presto perche' entro un anno potreste avere la possibilita' di diventare Padri Fondatori anche voi</h1>
      </div>
    </div>
    <CookiePopup />
    <Footer />
    </>
  );
};

export default Statuto;
