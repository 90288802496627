import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import '../styles/Notizie.css';

const Notizie = () => {
  const [notizie, setNotizie] = useState([]);
  const [pagina, setPagina] = useState(1);
  const [notiziePerPagina] = useState(6);
  const [totaleNotizie, setTotaleNotizie] = useState(0);
  const [expanded, setExpanded] = useState({});
  const [videoPlaying, setVideoPlaying] = useState({});

  useEffect(() => {
    fetch(`/api/fvN2C7KDJs5wSReQAWdqmG?page=${pagina}&limit=${notiziePerPagina}`)
      .then(res => res.json())
      .then(data => {
        setNotizie(data.notizie);
        setTotaleNotizie(data.totale);
      })
      .catch(err => console.error('Errore:', err));
  }, [pagina]);

  const toggleExpand = (id) => {
    setExpanded(prev => ({ ...prev, [id]: !prev[id] }));
  };

  const decodeHTMLEntities = (text) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = text;
    return textarea.value;
  };

  const formatText = (text) => {
    return text.replace(/([.!?])\s*/g, "$1\n");
  };

  const isImage = (url) => /\.(jpeg|jpg|gif|png|webp)$/i.test(url);
  const isVideo = (url) => /\.(mp4|webm|ogg)$/i.test(url);

  const haPagineSuccessive = pagina * notiziePerPagina < totaleNotizie;
  const haPaginePrecedenti = pagina > 1;

  const handleVideoPlay = (id) => {
    const videoElement = document.getElementById(`video-${id}`);
    if (videoElement) {
      videoElement.play();
      setVideoPlaying(prev => ({ ...prev, [id]: true }));
    }
  };

  return (
    <div className="padding">
      <div className="notizie-container">
        <h2 className="notizie-titolo">Ultime Notizie</h2>
        
        {/* Animazione con Framer Motion */}
        <AnimatePresence mode="wait">
          <motion.div 
            key={pagina} 
            initial={{ opacity: 0, y: 20 }} 
            animate={{ opacity: 1, y: 0 }} 
            exit={{ opacity: 0, y: -20 }} 
            transition={{ duration: 0.4 }}
          >
            <div className="notizie-grid">
              {notizie.map((item) => (
                <div key={item.id} className="notizia-card">
                  <p className="notizia-data">{item.data_pubblicazione}</p>
                  <h3 className="notizia-titolo">{decodeHTMLEntities(item.titolo)}</h3>
                  <h4 className="notizia-sottotitolo">{decodeHTMLEntities(item.sottotitolo)}</h4>

                  {item.immagine && isImage(item.immagine) && (
                    <img
                      src={item.immagine}
                      alt="Immagine notizia"
                      className="notizia-immagine"
                      onError={(e) => (e.target.style.display = 'none')}
                    />
                  )}

                  {item.immagine && isVideo(item.immagine) && (
                    <div className="video-container">
                      <video
                        id={`video-${item.id}`}
                        className="notizia-video"
                        controls
                        controlsList="nodownload noplaybackrate"
                        disablePictureInPicture
                        onPlay={() => setVideoPlaying(prev => ({ ...prev, [item.id]: true }))}
                      >
                        <source src={item.immagine} type="video/mp4" />
                        Il tuo browser non supporta la riproduzione video.
                      </video>
                      
                      {!videoPlaying[item.id] && (
                        <button
                          className="custom-play-button"
                          onClick={() => handleVideoPlay(item.id)}
                        >
                          ▶
                        </button>
                      )}
                    </div>
                  )}

                  <p className={`notizia-descrizione ${expanded[item.id] ? "expanded" : ""}`}>
                    {expanded[item.id] ? decodeHTMLEntities(formatText(item.descrizione)) : decodeHTMLEntities(formatText(item.descrizione.substring(0, 150))) + "..."}
                  </p>

                  <button onClick={() => toggleExpand(item.id)} className="notizia-bottone">
                    {expanded[item.id] ? "Mostra meno" : "Espandi"}
                  </button>

                  {item.link_opzionale && (
                    <a href={item.link_opzionale} target="_blank" rel="noopener noreferrer" className="notizia-link">
                      Continua a leggere
                    </a>
                  )}
                </div>
              ))}
            </div>
          </motion.div>
        </AnimatePresence>

        {/* Paginazione con transizione fluida */}
        {(haPaginePrecedenti || haPagineSuccessive) && (
          <div className="pagination">
            {haPaginePrecedenti && (
              <motion.button 
                whileHover={{ scale: 1.05 }} 
                whileTap={{ scale: 0.95 }}
                onClick={() => setPagina(pagina - 1)}
              >
                ← Precedente
              </motion.button>
            )}
            <span> Pagina {pagina} </span>
            {haPagineSuccessive && (
              <motion.button 
                whileHover={{ scale: 1.05 }} 
                whileTap={{ scale: 0.95 }}
                onClick={() => setPagina(pagina + 1)}
              >
                Successivo →
              </motion.button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Notizie;





