import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
  const [isTransparent, setIsTransparent] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isChiSiamoOpen, setIsChiSiamoOpen] = useState(false);
  const [isMaterialiOpen, setIsMaterialiOpen] = useState(false);
  const [isTrasparenzaOpen, setIsTrasparenzaOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsTransparent(window.scrollY > 30);
      setIsScrolled(window.scrollY > 80);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleChiSiamo = () => {
    setIsChiSiamoOpen(!isChiSiamoOpen);
    setIsMaterialiOpen(false); 
  };
  
  const toggleMateriali = () => {
    setIsMaterialiOpen(!isMaterialiOpen);
    setIsChiSiamoOpen(false);
  };

  const toggleTrasparenza = () => {
    setIsTrasparenzaOpen(!isTrasparenzaOpen);
    setIsChiSiamoOpen(false);
  };

  return (
    <header id="navbar" className={`navbar ${isTransparent ? 'transparent' : ''} ${isScrolled ? 'scrolled' : ''} ${isOpen ? 'open' : ''}`}>
      <div className="left-buttons">
        <div className="dropdown">
          <button className="dropbtn" onClick={toggleChiSiamo}>
            Chi Siamo <span className={`arrow ${isChiSiamoOpen ? 'rotated' : ''}`}>▶</span>
          </button>
          <div className={`dropdown-content ${isChiSiamoOpen ? 'open' : ''}`}>
            <Link to="/punti-programmatici">Punti Programmatici</Link>
            <Link to="/Fondatori">I Fondatori</Link>
          </div>
        </div>
        <div className="dropdown">
          <button className="dropbtn" onClick={toggleMateriali}>
            Materiali <span className={`arrow ${isMaterialiOpen ? 'rotated' : ''}`}>▶</span>
          </button>
          <div className={`dropdown-content ${isMaterialiOpen ? 'open' : ''}`}>
            <Link to="/gadgets">Gadgets</Link>
            <Link to="/Manifesti">Download</Link>
          </div>
        </div>

        <Link to="/News"><button className="button-3">Ultime News</button></Link>
      </div>

      <div className="logo1">
        <Link to="/"><img src="/assets/images/logo.png" alt="Logo" className={isScrolled ? 'small-logo' : ''} /></Link>
      </div>

      <div className="right-button">
        <div className="dropdown">
          <button className="dropbtn" onClick={toggleTrasparenza}>
            Trasparenza <span className={`arrow ${isTrasparenzaOpen ? 'rotated' : ''}`}>▶</span>
          </button>
          <div className={`dropdown-content ${isTrasparenzaOpen ? 'open' : ''}`}>
          <Link to="/statuto">Lo Statuto</Link>
          </div>
        </div>

        <Link to="/Donazioni"><button className="button-4">Dona <img className="icon" src="/assets/icon/freccia.svg" alt="" /></button></Link>
        <Link to="/tesserati"><button className="button-4">Diventa Sostenitore <img className="icon" src="/assets/icon/freccia.svg" alt="" /></button></Link>
      </div>

      <div className={`menu-icon ${isOpen ? 'visible' : ''}`} onClick={toggleMenu}>
        &#9776;
      </div>

      <div className={`dropdown-menu ${isOpen ? 'open' : ''}`}>
       {isOpen && (
        <div className="logo-fixed">
          <img src="/assets/images/logo.png" alt="Logo" />
        </div>
      )}
        <Link to="#" className="dropdown-item" onClick={toggleChiSiamo}>
          Chi siamo <span className={`arrow ${isChiSiamoOpen ? 'rotated' : ''}`}>▶</span>
        </Link>
        <div className={`sub-dropdown-content ${isChiSiamoOpen ? 'open' : ''}`}>
          <Link to="/Fondatori" className="dropdown-item">I Fondatori</Link>
          <Link to="/punti-programmatici" className="dropdown-item">Punti Programmatici</Link>
        </div>

        <Link to="#" className="dropdown-item" onClick={toggleMateriali}>
          Materiali <span className={`arrow ${isMaterialiOpen ? 'rotated' : ''}`}>▶</span>
        </Link>
        <div className={`sub-dropdown-content ${isMaterialiOpen ? 'open' : ''}`}>
          <Link to="/Manifesti" className="dropdown-item">Download</Link>
          <Link to="/gadgets" className="dropdown-item">I nostri Gadgets</Link>
        </div>

        <Link to="#" className="dropdown-item" onClick={toggleTrasparenza}>
          Trasparenza <span className={`arrow ${isTrasparenzaOpen ? 'rotated' : ''}`}>▶</span>
        </Link>
        <div className={`sub-dropdown-content ${isTrasparenzaOpen ? 'open' : ''}`}>
        <Link to="/statuto" className="dropdown-item">Lo Statuto</Link>
        </div>
        <Link to="/news" className="dropdown-item">Ultime News</Link>
        <Link to="/donazioni" className="dropdown-item-2">DONA</Link>
        <Link to="/tesserati" className="dropdown-item-2">Tesserati Ora</Link>
      </div>
    </header>
  );
};

export default Navbar;
