import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Tesserati from './pages/Tesserati';
import Home from './pages/Home'
import InformativaPrivacy from './pages/InformativaPrivacy'
import CookiePolicy from './pages/CookiePolicy';
import Manifesti from './pages/Manifesti';
import Fondatori from './pages/Fondatori';
import PuntiProgrammatici from './pages/PuntiProgrammatici';
import RegionPage from './components/RegionPage';
import EventiCompleti from './pages/eventiCompleti';
import Donazioni from './pages/Donazioni';
import GadgetsPage from './pages/GadgetsPage'
import NewsPage from './pages/Notizie';
import Statuto from './pages/Statuto';

function App() {
  return (
    <Router>
      <Routes>
        {/* Definisci le rotte per ogni pagina */}
        <Route path="/" element={<Home />} />
        <Route path="/tesserati" element={<Tesserati />} />
        <Route path="/InformativaPrivacy" element={<InformativaPrivacy />} />
        <Route path="/CookiePolicy" element={<CookiePolicy />} />
        <Route path="/Manifesti" element={<Manifesti />} />
        <Route path="/Fondatori" element={<Fondatori />} />
        <Route path="/punti-programmatici" element={<PuntiProgrammatici />} />
        <Route path="/eventi/:region" element={<RegionPage />} />
        <Route path="/eventi" element={<EventiCompleti />} />
        <Route path="/donazioni" element={<Donazioni />} />
        <Route path="/gadgets" element={<GadgetsPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/statuto" element={<Statuto/>} />
        {/* Per gestire il fallback per una pagina non trovata */}
        <Route path="*" element={<h2>404 - Pagina non trovata</h2>} />
      </Routes>
    </Router>
  );
}


export default App;
