import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import '../styles/Slideshow.css';

const Slideshow = () => {
  const [screenSize, setScreenSize] = useState('desktop');
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  // ✅ Responsive check 3 livelli
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width <= 768) setScreenSize('mobile');
      else if (width <= 1280) setScreenSize('tablet');
      else setScreenSize('desktop');
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // ✅ Array slide
  const slides = [
    {
      image: '/assets/images/slideTesseramento.jpg',
      imageTablet: '/assets/images/tabletSlideTesseramento.jpg',
      imageMobile: '/assets/images/mobileSlideTesseramento.jpg',
      buttonText: 'Tesserati Ora',
      buttonLink: '/tesserati',
    },
    {
      image: '/assets/images/ManDesktop.png',
      imageTablet: '/assets/images/tabletMan.png',
      imageMobile: '/assets/images/mobileMan.png',
      buttonText: 'Clicca qui',
      buttonLink: '/Manifesti',
    },
    {
      image: '/assets/images/PNNDesktop.jpg',
      imageTablet: '/assets/images/PNNTablet.jpg',
      imageMobile: '/assets/images/PNNMobile.jpg',
    },
    {
      image: '/assets/images/DonazioniDesktop.png',
      imageTablet: '/assets/images/DonazioniTablet.png',
      imageMobile: '/assets/images/DonazioniMobile.png',
      buttonText: 'Clicca qui',
      buttonLink: '/Donazioni',
    },
    {
      image: '/assets/images/GadgetsDesktop.png',
      imageTablet: '/assets/images/GadgetsTablet.png',
      imageMobile: '/assets/images/GadgetsMobile.png',
      buttonText: 'Clicca qui',
      buttonLink: '/gadgets',
    },
  ];

  return (
    <div className="slideshow-container">
      {/* ✅ SWIPER */}
      <Swiper
        modules={[Autoplay]}
        autoplay={{ delay: 10000, disableOnInteraction: false }}
        speed={1000}
        loop={true}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className="mySlides">
            <img
              src={
                screenSize === 'mobile'
                  ? slide.imageMobile
                  : screenSize === 'tablet'
                  ? slide.imageTablet
                  : slide.image
              }
              alt={`Slide ${index + 1}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            {slide.buttonText && (
              <a href={slide.buttonLink} className="tesseramento-button">
                {slide.buttonText}
              </a>
            )}
          </SwiperSlide>
        ))}
      </Swiper>

      {/* ✅ Frecce */}
      <button
        className="prev"
        onClick={() => swiperRef.current.slidePrev()}
      >
        &#10094;
      </button>
      <button
        className="next"
        onClick={() => swiperRef.current.slideNext()}
      >
        &#10095;
      </button>

      {/* ✅ Dots */}
      <div className="dot-container">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`dot ${activeIndex === index ? 'active' : ''}`}
            onClick={() => swiperRef.current.slideToLoop(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slideshow;

