import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import '../styles/Social.css';

const socialLinks = [
  { name: 'YouTube Shorts', description: 'Guarda i nostri contenuti su Shorts per restare aggiornato su tutte le nostre iniziative', icon: '/assets/icon/shorts.png', link: 'https://www.youtube.com/@PattoperilNord/shorts' },
  { name: 'Facebook', description: 'Segui Patto per il Nord su Facebook!', icon: '/assets/icon/facebook.png', link: 'https://www.facebook.com/pattoperilnord1/' },
  { name: 'Instagram', description: 'Segui il profilo anche su Instagram di Patto per il Nord', icon: '/assets/icon/instagram.png', link: 'https://www.instagram.com/pattoperilnord/' },
  { name: 'X', description: 'Segui l’account X di Patto per il Nord.', icon: '/assets/icon/x.png', link: 'https://x.com/Pattoperilnord' },
  { name: 'Telegram', description: 'Segui il canale Telegram di Patto per il Nord.', icon: '/assets/icon/telegram.png', link: 'https://t.me/pattoperilnord' },
  { name: 'YouTube', description: 'Iscriviti al nostro canale YouTube per restare aggiornati su tutti i nostri nuovi contenuti', icon: '/assets/icon/youtube.png', link: 'https://www.youtube.com/@PattoperilNord' },
];

const Socials = () => {
  return (
    <>
      {/* Carosello visibile su Tablet e Desktop */}
      <div className="social-media-section swiper-container">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          navigation
          pagination={{ clickable: true }}
          breakpoints={{
            0: { slidesPerView: 1 }, // Mobile
            768: { slidesPerView: 2 }, // Tablet verticale
            1024: { slidesPerView: 3 }, // Tablet orizzontale
            1200: { slidesPerView: 4 }, // Desktop
            1400: { slidesPerView: 5 } // Grandi schermi
          }}
        >
          {socialLinks.map((social, index) => (
            <SwiperSlide key={index}>
              <div className="social-card">
                <h3>{social.name}</h3>
                <p>{social.description}</p>
                <a href={social.link} className="social-button" target="_blank" rel="noopener noreferrer">
                  <img src={social.icon} alt={`${social.name} icon`} className="social-icon" />
                  Scopri di più
                </a>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Lista normale visibile solo su Mobile */}
      <div className="social-media-section-list">
        {socialLinks.map((social, index) => (
          <div key={index} className="social-card">
            <h3>{social.name}</h3>
            <p>{social.description}</p>
            <a href={social.link} className="social-button" target="_blank" rel="noopener noreferrer">
              <img src={social.icon} alt={`${social.name} icon`} className="social-icon" />
              Scopri di più
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default Socials;
