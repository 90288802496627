import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CookiePopup from '../components/CookiePopup';
import Gadgets from '../components/Gadgets';

const GadgetsPage = () => {
  return (
    <div>
      <Navbar />
      <Gadgets />
      <CookiePopup />
      <Footer />
    </div>
  );
};

export default GadgetsPage;
