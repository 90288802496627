import React, { useEffect, useState } from 'react';
import { MapContainer, GeoJSON } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';
import '../styles/Mappa.css';

const Mappa = () => {
  const navigate = useNavigate();
  const [geoData, setGeoData] = useState(null);

  // Stati per gestire zoom e center dinamici
  const [zoomLevel, setZoomLevel] = useState(7); // Zoom default desktop
  const [centerLevel, setCenterLevel] = useState([45, 11]); // Center default desktop

  // Effetto per settare valori in base alla dimensione schermo
  useEffect(() => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 768) {
      setZoomLevel(6); // Zoom più lontano su mobile
      setCenterLevel([44.5, 10.3]); // Centro più basso per mobile (es. per vedere tutta Italia)
    } else {
      setZoomLevel(7); // Zoom normale desktop
      setCenterLevel([44.8, 10]); // Centro leggermente più alto per desktop
    }
  }, []);

  // Caricamento GeoJSON
  useEffect(() => {
    fetch('/assets/geojson/mappa.geojson')
      .then((response) => response.json())
      .then((data) => setGeoData(data))
      .catch((error) => console.error('Errore nel caricamento GeoJSON:', error));
  }, []);

  // Stile regioni
  const regionStyle = {
    fillColor: '#e6e6e6',
    weight: 1,
    opacity: 1,
    color: '#333',
    fillOpacity: 1,
  };

  // Gestione interazione sulle regioni
  const onEachRegion = (feature, layer) => {
    const regionName = feature.properties.DEN_REG;
    layer.bindTooltip(regionName, { permanent: false, direction: 'center' });

    layer.on({
      click: () => navigate(`../eventi/${regionName}`),
      mouseover: (e) => e.target.setStyle({ fillColor: '#66c2a5' }),
      mouseout: (e) => e.target.setStyle(regionStyle),
    });
  };

  return (
    <div className="padding">
      <h1>I PROSSIMI APPUNTAMENTI</h1>
      <h2>Clicca sulla tua regione o vai all'elenco completo</h2>

      <div id="mappa">
        {geoData && (
          <MapContainer
            center={centerLevel} // Centro dinamico
            zoom={zoomLevel} // Zoom dinamico
            style={{ height: '100%', width: '100%' }} // OBBLIGATORIO
            scrollWheelZoom={false}
            dragging={false}
            doubleClickZoom={false}
            touchZoom={false}
            zoomControl={false}
            attributionControl={false}
          >
            <GeoJSON data={geoData} style={regionStyle} onEachFeature={onEachRegion} />
            <div className="leaflet-control leaflet-control-attribution leaflet-bottom leaflet-right">
              Dati: ISTAT - Licenza: <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noopener noreferrer">CC BY 4.0</a>
            </div>
          </MapContainer>
        )}
      </div>

      <button className="btn-complete-list" onClick={() => navigate("../eventi")}>
        VAI ALL'ELENCO COMPLETO
      </button>
    </div>
  );
};

export default Mappa;

