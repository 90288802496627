import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CookiePopup from '../components/CookiePopup';
import Socials from '../components/Social';
import Mappa from '../components/Mappa';
import Slideshow from '../components/Slideshow';


const Home = () => {
  return (
    <div>
      <Navbar />
      <Slideshow />
      <Mappa />
      <Socials />
      <CookiePopup />
      <Footer />
    </div>
  );
};

export default Home;
