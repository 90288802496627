import React, { useEffect, useState } from "react";
import "../styles/Manifesti.css";

  const ManifestiResource = () => {
    const [showManifesti, setShowManifesti] = useState(false);
    const [showVolantini, setShowVolantini] = useState(false);
    const [showLogotipi, setShowLogotipi] = useState(false);
    const [manifesti, setManifesti] = useState([]);
    const [volantini, setVolantini] = useState([]);
    const [logotipi, setLogotipi] = useState([]);
  
    useEffect(() => {
        fetch("/api/VeMUA7S69X8nzGQ4usxEwf", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors", // Abilita CORS
        })
          .then((response) => response.json())
          .then((data) => {
            setManifesti(data.manifesti);
            setVolantini(data.volantini);
            setLogotipi(data.logotipi);
          })
          .catch((error) => console.error("Errore nel caricamento:", error));
      }, []);


      const handleDownload = (pdfUrl) => {
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = pdfUrl.split("/").pop(); // Nome del file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
      <div className="posters-page">
         <div className="navbar-spacing"></div> 
        <h1>Download manifesti e volantini</h1>
  
        {/* Sezione Manifesti */}
        <div className="section">
          <div className="section-header" onClick={() => setShowManifesti(!showManifesti)}>
            <h2>Manifesti 70x100</h2>
            <button>{showManifesti ? "-" : "+"}</button>
          </div>
          {showManifesti && (
            <div className="posters-container">
              {manifesti.map((poster, index) => (
                <div className="poster-card" key={index}>
                  <a href={poster.pdf} target="_blank" rel="noopener noreferrer">
                    <img src={poster.image} alt={poster.title} />
                  </a>
                  <p>{poster.subtitle}</p>
                </div>
              ))}
            </div>
          )}
        </div>
  
        {/* Sezione Volantini */}
        <div className="section">
          <div className="section-header" onClick={() => setShowVolantini(!showVolantini)}>
            <h2>Volantini formato A5</h2>
            <button>{showVolantini ? "-" : "+"}</button>
          </div>
          {showVolantini && (
            <div className="posters-container">
              {volantini.map((poster, index) => (
                <div className="poster-card" key={index}>
                  <a href={poster.pdf} target="_blank" rel="noopener noreferrer">
                    <img src={poster.image} alt={poster.title} />
                  </a>
                  <p>{poster.subtitle}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* Sezione Logotipi */}
        <div className="section">
          <div className="section-header" onClick={() => setShowLogotipi(!showLogotipi)}>
            <h2>Logotipi</h2>
            <button>{showLogotipi ? "-" : "+"}</button>
          </div>
          {showLogotipi && (
            <div className="posters-container">
              {logotipi.map((poster, index) => (
                <div className="poster-card" key={index}>
                  <a href={poster.pdf} target="_blank" rel="noopener noreferrer">
                    <img src={poster.image} alt={poster.title} />
                  </a>
                  <p>{poster.subtitle}</p>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="navbar-spacing"></div> 
      </div>
    );
  };
  
  export default ManifestiResource;