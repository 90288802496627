import React, { useState, useEffect } from 'react';
import '../styles/Gadgets.css';

const Gadgets = () => {
  const [gadgetsData, setGadgetsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    nome: '',
    cognome: '',
    telefono: '',
    email: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    fetch('/api/nhcmGyPqBsQ86xeb5MSUAR', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (!response.ok) throw new Error('Errore caricamento gadgets');
        return response.json();
      })
      .then((data) => {
        setGadgetsData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Errore nel caricamento:', error);
        setLoading(false);
      });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/jgGmXd3kncpRuSy76H5Nae', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage('Richiesta inviata con successo!');
        setErrorMessage('');
        setFormData({ nome: '', cognome: '', telefono: '', email: '' });
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Errore nell\'invio della richiesta.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error('Errore durante l\'invio:', error);
      setErrorMessage('Errore durante l\'invio della richiesta.');
      setSuccessMessage('');
    }
  };

  return (
    <section className="gadgets-container">
      <div className="gadgets-navbar-spacing"></div>
      <h1 className="gadgets-title">I Nostri Gadgets</h1>
      <div className="gadgets-navbar-spacing"></div>

      {loading ? (
        <p>Caricamento in corso...</p>
      ) : (
        <div className="gadgets-cards-container">
          {gadgetsData.map((gadget, index) => (
            <div className="gadget-card" key={index}>
              <img
                src={gadget.image}
                alt={gadget.name}
                className="gadget-card-image"
                loading="lazy"
              />
              <div className="gadget-card-content">
                <h2 className="gadget-card-name">{gadget.name}</h2>
                <p className="gadget-card-description">{gadget.description}</p>
              </div>
            </div>
          ))}
        </div>
      )}

      <form className="gadget-form" onSubmit={handleSubmit}>
        <h2>Richiedi Informazioni</h2>
        <input
          type="text"
          name="nome"
          placeholder="Nome"
          value={formData.nome}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="cognome"
          placeholder="Cognome"
          value={formData.cognome}
          onChange={handleInputChange}
          required
        />
        <input
          type="tel"
          name="telefono"
          placeholder="Telefono"
          value={formData.telefono}
          onChange={handleInputChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <button type="submit">Invia Richiesta</button>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </form>
    </section>
  );
};

export default Gadgets;
