import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CookiePopup from '../components/CookiePopup';
import Notizie from '../components/NotizieCard';

const NewsPage = () => {
  return (
    <div>
      <Navbar />
      <Notizie />
      <CookiePopup />
      <Footer />
    </div>
  );
};

export default NewsPage;
