import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import FondatoriPage from '../components/FondatoriPage';
import CookiePopup from '../components/CookiePopup';



const Fondatori = () => {
  return (
    <div>
      <Navbar />
      <FondatoriPage />
      <CookiePopup />
      <Footer />
    </div>
  );
};

export default Fondatori;