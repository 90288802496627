import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CookiePopup from '../components/CookiePopup';
import DonationForm from '../components/DonationForm';

const Donazioni = () => {
  return (
    <>
    <Navbar />
    <div className="donation-page">

      <h1>Supporta la nostra associazione</h1>
      <p>Compila il form per effettuare una donazione. Grazie al tuo aiuto possiamo continuare le nostre attività sul territorio per il Nord.</p>
      <DonationForm />
    </div>
    <CookiePopup />
    <Footer />
    </>
  );
};

export default Donazioni;
