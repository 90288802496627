import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { PDFDocument } from 'pdf-lib';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import '../styles/PuntiProgrammatici.css';
import { FaBalanceScale, FaGavel, FaArrowDown, FaSchool, FaLeaf, FaLandmark, FaUsers, FaVoteYea, FaBolt, FaBroadcastTower } from 'react-icons/fa';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;



const PuntiProgrammaticiCard = () => {
  const [visualizzaSlideshow, setVisualizzaSlideshow] = useState(false); // Stato ON/OFF
  const [numPages, setNumPages] = useState(null); // Numero pagine del PDF
  const [currentPage, setCurrentPage] = useState(1);


  const puntiProgrammatici = [
    {
      title: "I principi di Patto per il Nord",
      icon: <FaLandmark />,
      description: [
        "Riforma costituzionale in senso federale per l’autogoverno del Nord, del centro e del sud superando il marcio centralismo partitico e assistenzialista, con un moderno stato FEDERALE, che sappia rispettare tutti i popoli che lo costituiscono, indipendentemente dalla loro consistenza numerica.",
        "Smantellamento e SOPPRESSIONE delle agenzie fiscali a cominciare dall'agenzia delle entrate, delegandone le funzioni ai singoli comuni, che dopo aver trattenuto le quote a loro spettanti, ne trasferiranno una  ulteriore quota a provincia, regione e stato federale.",
        "FUSIONE delle regioni con relativo risparmio di soldi pubblici per la creazione di una macroregione Padano-alpina, una centro-appenninica e una mediterraneo-insulare a modello dei LAND TEDESCHI.",
        "Per la costruzione di una Europa fondata sulla AUTONOMIA, IL FEDERALISMO, il rispetto e la solidarietà diretta tra tutti i popoli del continente, quindi tra i nostri popoli ed ogni altro popolo e territorio d'Europa",
        "Uno stato federale snello che pensi alla politica estera e alla difesa e lasci la possibilità ai privati e alle comunità locali di occuparsi di tutti i servizi.",
      ],
    },
    {
      title: "Obiettivi come sindacato del Nord",
      icon: <FaGavel />,
      description: [
        "Salario basato sul costo della vita dei territori, la contrattazione collettiva non basta come Milano città ha ampiamente dimostrato. Nel mondo moderno - vedi il “London living wage” - gli stipendi non sono più nazionali ma territoriali.",
        "PRECEDENZA ai residenti in base all’anzianità di residenza regionale nell’assegnazione di lavoro pubblico, di abitazioni pubbliche, di assistenza sanitaria, di contributi pubblici",
        "Totale detassazione di tutte le PENSIONI maturate attraverso il pagamento di contributi, frutto di anni di sacrificio dei lavoratori",
        "Affinché la SCUOLA pubblica diventi l'anello di congiunzione tra mondo del sapere, mondo del lavoro e cultura della comunità locale, su base e coordinamento provinciale.",
        "Abolizione del valore legale del titolo di studio per le lauree. Premiare il merito e non “sceglierlo” per legge!",
        "Per la riaffermazione delle CULTURE, delle tradizioni, della storia, delle lingue, della moralità di ogni comunità.",
        "Per una legislazione che obblighi gli istituti finanziari a facilitare l'accesso al credito con prestiti agevolati per l' AGRICOLTURA, l'ARTIGIANATO e qualunque attività di MONTAGNA.",
        "Per l’utilizzo di una INTELLIGENZA ARTIFICIALE consapevole dei valori, della cultura e del rispetto della vita della nostra società.",
        "Affinché la GIUSTIZIA  venga amministrata dalla VOLONTÀ DEL POPOLO garantendo la certezza della pena e la responsabilità diretta dei magistrati che sbagliano",
        "Istituzione del REATO DI DANNEGGIAMENTO DELLA STORICITÀ a monumenti federali e locali",
        "Per il blocco di ogni SPECULAZIONE EDILIZIA sulle aree agricole e per il recupero degli stabili storici, attraverso la rigenerazione urbana e la difesa storico culturale e paesaggistica delle campagne.",
        "Difesa del nostro TESSUTO ECONOMICO produttivo attraverso una lotta serrata e costante a tasse, accise, balzelli, attraverso la soppressione totale di ogni forma di assistenzialismo, clientelismo e sperpero di denaro pubblico.",
        "Investire nell’educazione ambientale, finanziaria e previdenziale. Solo conoscendo quello che ci riguarderà IN FUTURO possiamo essere liberi e consapevoli nello scegliere.",
      ],
    },
    {
      title: "Battaglie identitarie",
      icon: <FaUsers />,
      description: [
        "Nel rispetto di tutte le fedi e di tutte le religioni, RICONOSCERE la cultura di riferimento, i valori e le NOSTRE RADICI CRISTIANE. Chi disdegna il crocifisso piuttosto che le nostre tradizioni- anche alimentari - sarà libero di andarsene da casa nostra.",
        "Vendita della Rai - oggi circa 30 emittenti tra radio e canali TV - e soppressione del relativo Canone PER SEMPRE.",
        "Garantire alle imprese maggiore libertà: meno burocrazia e più CONCORRENZA che genera EFFICIENZA.",
        "La politica come SERVIZIO e non professione attraverso il taglio e l’adeguamento degli stipendi parlamentari alla media istat dei salari.",
        "Il futuro energetico è l’idrogeno! Energia bene pubblico con le rinnovabili che in eccesso producono idrogeno da utilizzare all'occorrenza e per le auto.",
        "Introdurre lo strumento dei referendum consultivi per tutti i livelli istituzionali e che siano vincolanti per le amministrazioni pubbliche locali, regionali e federali.",
        "Modifica costituzionale con tetto alla pressione fiscale.",
      ],
    }
  ];

  const downloadPage = async (pdfUrl, pageNumber) => {
    const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const newPdfDoc = await PDFDocument.create();
  
    const [copiedPage] = await newPdfDoc.copyPages(pdfDoc, [pageNumber - 1]);
    newPdfDoc.addPage(copiedPage);
  
    const pdfBytes = await newPdfDoc.save();
  
    // Download
    const blob = new Blob([pdfBytes], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `Punti-Programmatici-Grafica-Pagina-${pageNumber}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="punti-programmatici-page">
      
    {/* SWITCH per passare da punti a slideshow */}
    <div className="switch-container">
        <label className="switch">
          <input type="checkbox" checked={visualizzaSlideshow} onChange={() => setVisualizzaSlideshow(!visualizzaSlideshow)} />
          <span className="slider round"></span>
        </label>
        <span>{visualizzaSlideshow ? "Visualizza Punti" : "Visualizza Slideshow"}</span>
      </div>

      {/* CONTENUTI CON ANIMAZIONE */}
      <div className={`content ${visualizzaSlideshow ? 'fade-out' : 'fade-in'}`}>
        {!visualizzaSlideshow && (
          <>
            <header className="header">
              <h1>I Punti Programmatici di Patto per il Nord</h1>
              <p>Il nostro piano programmatico è fondato sulla costruzione di un moderno stato federale e sulla difesa dei diritti dei cittadini attraverso autonomia e progresso.</p>
            </header>

            <div className="punti-grid">
              {puntiProgrammatici.map((punto, index) => (
                <Card punto={punto} key={index} />
              ))}
            </div>

            <div className="PButton-container">
              <a href="/assets/pdf/Punti-Programmatici.pdf" download>Scarica il Pdf testuale</a>
            </div>

          </>
        )}
      </div>

    {/* SLIDESHOW CON SWIPER PDF */}
    {visualizzaSlideshow && (
      <div className="slideshow-container1">
        <h1>Slideshow Punti Programmatici (PDF)</h1>
        <Document
          file="/assets/pdf/Punti-Programmatici-Grafica.pdf" // Percorso al tuo PDF
          onLoadSuccess={({ numPages }) => setNumPages(numPages)} // Prende numero pagine
          loading="Caricamento PDF..."
        >
          {numPages && (
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={30}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              autoplay={{ delay: 8000 }}
              loop={true}
              onSlideChange={(swiper) => setCurrentPage(swiper.realIndex + 1)}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <SwiperSlide key={index}>
                  <div className="slide-content-wrapper">
                  <button
                    className="download-btn"
                    onClick={() => downloadPage('/assets/pdf/Punti-Programmatici-Grafica.pdf', currentPage)}
                    title={`Scarica la pagina ${currentPage}`}
                  >
                    <FaArrowDown />
                  </button>

                  <Page pageNumber={index + 1} width={800}  loading={<div>Caricamento...</div>} />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
           <div className="PButton-container">
           <a href="/assets/pdf/Punti-Programmatici-Grafica.pdf" download>Scarica il Pdf grafico</a>
            </div>
          
        </Document>
      </div>
    )}
    
    </div>
  );
};

// Card invariata
const Card = ({ punto }) => {
  const [expanded, setExpanded] = useState(false);
  const maxLines = 3;

  return (
    <div className="cardPP">
      <div className="card-icon">{punto.icon}</div>
      <h2>{punto.title}</h2>
      <ul className={`description ${expanded ? 'expanded' : ''}`}>
        {punto.description.slice(0, expanded ? punto.description.length : maxLines).map((desc, i) => (
          <li key={i}>{desc}</li>
        ))}
      </ul>
      {!expanded && punto.description.length > maxLines && (
        <button className="expand-btn" onClick={() => setExpanded(true)}>Espandi...</button>
      )}
      {expanded && (
        <button className="collapse-btn" onClick={() => setExpanded(false)}>Nascondi</button>
      )}
    </div>
  );
};

export default PuntiProgrammaticiCard;
